@font-face {
   font-family: 'Roboto';
   src: url('./Roboto/Roboto-Regular.ttf') format('truetype');
   font-weight: 400;
   font-style: normal;
 }
 
 @font-face {
   font-family: 'Roboto';
   src: url('./Roboto/Roboto-Regular.ttf') format('truetype');
   font-weight: 700;
   font-style: normal;
 }

 @font-face {
   font-family: 'Nunito';
   src: url('./Nunito/Nunito-VariableFont_wght.ttf') format('truetype');
   font-weight: 400;
   font-style: normal;
 }
 
 @font-face {
   font-family: 'Nunito';
   src: url('./Nunito/Nunito-VariableFont_wght.ttf') format('truetype');
   font-weight: 700;
   font-style: normal;
 }


body {
background-color: #333 !important;
font-family: Nunito;
}


.Login_Container{
   margin-top: 100px;
   
}

.list_icons{
   border-bottom: 1px solid #333;

}
.list_icons:last-child{
   border-bottom: none;
}

.dashboard_container{
   display: 'flex'
}

.sidenav_styling{
   background-color: #131212;
   color: white;
   height: 100%;
}

.change_font{
   font-family: 'Courier New', Courier, monospace;
}

.card_heading{
   font-family: Nunito;
   font-weight: 600;
   font-size: 18px;
   color:white;
}

.card_sub_heading{
   font-family: Nunito;
   font-weight: 400;
   font-size: 15px;
   color:white;
}


.card_style{
   color:red;
}

.card_main_container{
   background-color: #333 !important;
   font-family: Nunito;
}

.List_titles .css-10hburv-MuiTypography-root{
   font-family: Nunito !important; 
}

.login_button {
   font-family: Nunito !important; 
   border: 1px solid white !important;
   color: white !important;
}

.List_titles.css-8u39c-MuiTypography-root{
   font-family: Nunito !important; 
}

.List_titles.css-j204z7-MuiFormControlLabel-root{
   font-family: Nunito !important; 
}

.account_table{
   background-color: #333;
   height: 80vh;
   width: 100%;
}

.account_table .css-gl260s-MuiDataGrid-columnHeadersInner{
   font-family: Nunito !important; 
   color:white;
   font-weight: 800;
}

.account_table .css-ptkaw2-MuiDataGrid-root .MuiDataGrid-row{
   font-family: Nunito !important; 
   color:white;
}
.account_table .css-ptkaw2-MuiDataGrid-root .MuiDataGrid-withBorderColor{
   border-color:rgba(17, 17, 17, 0.3);
}
.account_table .css-ptkaw2-MuiDataGrid-root{
   border-color:rgba(17, 17, 17, 0.5);
}
.account_table .css-rtrcn9-MuiTablePagination-root{
   color:white;
   font-family: Nunito !important; 
}

.account_table {
   width: 100%;
   overflow: auto; /* Enable horizontal scrolling if needed */
 }

 .account_table .css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled{
   color:white;
   font-family: Nunito !important;
 }

 .account_table .css-de9k3v-MuiDataGrid-selectedRowCount{
   color:white;
   font-family: Nunito !important;
 }

 .account_table .css-ptkaw2-MuiDataGrid-root .MuiDataGrid-row:nth-child(even) {
   background-color: rgb(255, 255, 255);
   color: black;
 }
 
 .account_table .css-ptkaw2-MuiDataGrid-root .MuiDataGrid-row:nth-child(odd) {
   background-color: #353131;
   color: white; /* Optional: Set text color for better visibility on black background */
 }

 .btn_open{
   margin-bottom: 10px !important;
   font-family: Nunito !important;
 }

 .add_account_popup .css-6b3s0t-MuiTypography-root{
   font-family: Nunito !important;
   color:white;
 }

 .add_account_popup .css-1y942vo-MuiButtonBase-root-MuiButton-root{
   font-family: Nunito !important;
   color:white;
 }

.add_account_popup .css-m9glnp-MuiPaper-root-MuiDialog-paper{
   background-color:#353131;
}
 
.input_styling .css-68fhrh-MuiTypography-root{
   font-family: Nunito !important;
   color:white;
}

.input_styling .css-1sn4lm3-MuiTypography-root{
   font-family: Nunito !important;
   color:white;
}

.input_styling .css-10botns-MuiInputBase-input-MuiFilledInput-input{
   color: white;
   font-family: Nunito !important;
}


.input_styling{
   margin-top: 40px;
}

.input_styling .css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input.css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input.css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input{
   color: white; 
   font-family: Nunito !important;
}

.focused{
   border-bottom: 1px solid red;
}


.account_table {
   overflow: auto;
   scrollbar-width: thin; /* Firefox */
   scrollbar-color: darkgray lightgray; /* Firefox */
 
   /* Webkit (Chrome, Safari) */
   &::-webkit-scrollbar {
     width: 12px;
   }
 
   &::-webkit-scrollbar-thumb {
     background-color: darkgray;
   }
 
   &::-webkit-scrollbar-track {
     background-color: lightgray;
   }
 }